import React from 'react'
import styled from 'styled-components'
import { colours, device } from '../../style/variables'

const Container = styled.div`
  margin: 2rem auto 3rem auto;
  text-align: center;

  @media ${device.desktop} {
    margin: 0.5rem auto 4rem auto;
    max-width: 1040px;
  }
`

const Title = styled.div`
  color: ${colours.black};
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin: 0 auto;
  max-width: 260px;

  @media ${device.tablet} {
    max-width: 100%;
    padding-top: 2rem;
  }

  @media ${device.desktop} {
    margin-left: 10px;
    max-width: 100%;
    text-align: left;
  }
`

const Highlight = styled.div`
  color: ${colours.grey};
  background-color: #fff1ef;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  margin: 1rem 0;
  padding: 2rem 1rem;
  text-align: center;
  width: 100vw;

  #h-linebreak {
    height: 10px;
  }

  @media ${device.desktop} {
    border-radius: 20px;
    max-width: 675px;
    text-align: left;
    padding: 1rem;
  }
`

const TextContainer = styled.div`
  margin: 1rem;
`

const Text = styled.div`
  color: ${colours.grey};
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  text-align: left;

  #linebreak {
    height: 20px;
  }

  #pbreak {
    height: 40px;
  }

  #highlighted {
    color: ${colours.black};
    font-weight: 700;
  }

  #subtitle {
    color: ${colours.black};
    font-style: italic;
  }

  @media ${device.desktop} {
    margin-top: 1.4rem;
    max-width: 650px;
  }
`

function Block1() {
  return (
    <Container>
      <Title>Les différents mandats de vente proposés par ALLOWA</Title>
      <Highlight>
        Vous avez décidé de vous faire accompagner par ALLOWA pour la vente de votre bien. Bravo !
        <div id="h-linebreak" />
        Vous allez bénéficier de services et conseils d’un professionnel afin d’atteindre votre
        objectif : <b>la vente de votre bien aux meilleures conditions</b>. Avant toute action,
        votre relation avec ALLOWA doit être sécurisée
      </Highlight>
      <TextContainer>
        <Text>
          <i>
            <u>Comment</u>
          </i>{' '}
          ?
          <br />
          En signant un mandat de vente qui permet d’encadrer la vente et d’en préciser les
          modalités. Toute vente effectuée par un professionnel de l’immobilier résulte en effet du
          contrat passé entre lui et le vendeur. La loi Hoguet qui régit l’activité des agents
          immobiliers est stricte sur ce point. Pas de recours à un agent sans signature de mandat
          de vente. Il s’agit d’un passage obligé pour tout vendeur faisant appel à un
          professionnel.
          <div id="linebreak" />
          <i>
            <u>A quoi sert-il</u>
          </i>{' '}
          ?
          <br />
          C’est un document officiel, numéroté dans le registre du professionnel de l’immobilier, et
          qui définit les modalités d’exécution de la vente ainsi que l’engagement des parties,
          leurs droits et obligations. On y retrouve les informations relatives au bien, au prix de
          vente, à la durée, aux modalités d’engagement, au montant des honoraires, entre autres.
          <div id="pbreak" />
          <span id="highlighted">Il existe différents mandats de vente</span> : mandat exclusif,
          mandat semi exclusif et mandat simple. Plusieurs choix s’offrent donc à vous Détaillons
          -les pour que vous puissiez trouver le mandat qui correspondra le mieux à vos attentes
          <div id="linebreak" />
          <span id="subtitle">Le mandat exclusif</span>
          <br />
          Seule l’agence avec laquelle vous avez signé le contrat peut agir.
          <br />
          Elle seule peut s’occuper de la publicité, des visites, négocier avec les candidats et
          finaliser la vente. Vous n’avez qu’un seul interlocuteur qui vous est entièrement dédié.
          <br />
          La durée d’exclusivité étant en général de trois mois, la vente peut être plus rapide du
          fait de la motivation de l’agent.
          <br />
          L’exclusivité assure une rémunération au professionnel puisqu’ il est quasiment certain de
          ‘faire‘ la vente, tous les moyens vont donc être déployés atteindre l’objectif. La
          visibilité du bien est maximale.
          <div id="linebreak" />
          <span id="subtitle">L’éventuel inconvénient ?</span>
          <br />
          Ce contrat est plus engageant pour le Vendeur que les autres mandats et ce pendant une
          certaine durée, en général 3 mois (sauf en cas de défaillance ou faute de l’agent); il
          offre donc moins de souplesse.
          <br />
          Si le bien n’est pas vendu au bout de trois mois, vous avez la possibilité de le
          renouveler bien sûr ou de signer un nouveau mandat (semi-exclusif ou simple).
          <div id="linebreak" />
          <span id="subtitle">L’avantage ?</span>
          <br />
          1 seul interlocuteur, moins de visites, moins de négociations avec des agences qui sont en
          concurrence et veulent vendre au plus vite mais pas au meilleur prix.
          <br />
          La mention ‘exclusivité’ figurant sur les annonces procure un sentiment de rareté et
          incite les prospects à agir vite.
          <div id="linebreak" />
          <span id="subtitle">Le mandat semi exclusif</span>
          <br />
          Ce type de mandat a beaucoup de similitudes avec le mandat exclusif. La seule différence ?
          Vous pouvez en tant que Vendeur vendre de votre côté par vos propres moyens sans avoir à
          rémunérer l’agence si vous y parvenez.
          <div id="linebreak" />
          <span id="subtitle">Le mandat simple</span>
          <br />
          C’est le contrat le plus souple.
          <br />
          Vous signez des mandats avec plusieurs agences qui travaillent alors en concurrence.
          <br />
          Le bien est très exposé car les agences vont publier les annonces chacune de leur côté.
          <br />
          Le prospect en recherche peut interpréter ces multiples annonces pour un même bien comme
          un produit difficile à vendre et sera plus facilement amené à faire une offre à la baisse.
          <br />
          L’agent immobilier ne sera pas forcement stimulé par la concurrence, préférant se
          consacrer à ses mandats exclusifs.
          <br />
          Ce type de mandat vous permet aussi de vendre par vous-même en toute liberté.
          <div id="pbreak" />
          <span id="highlighted">Comment choisir le type de mandat dont vous avez besoin ?</span>
          <div id="linebreak" />
          Tout dépend de vos besoins, votre temps et stratégie et du feeling avec l’agent. Si vous
          souhaitez vous investir le moins possible personnellement et n’avoir qu’un interlocuteur
          ultra motivé en qui vous avez toute confiance, alors le mandat exclusif s’impose.
          <br />
          En revanche, si vous avez davantage de temps à consacrer aux relations avec les agents,
          aux visites, et si vous pensez être amené à vendre le bien de votre côté, optez pour le
          mandat simple. L’issue est plus incertaine tant au niveau du timing que du prix final,
          mais vous avez la souplesse que ne permet pas le mandat exclusif.
          <br />
          Enfin, si vous souhaitez profiter de votre relationnel pour vendre par vous-même tout en
          bénéficiant d’un agent qui vous est dédié, le mandat semi exclusif est fait pour vous.
        </Text>
      </TextContainer>
    </Container>
  )
}

export default Block1
