// Colours
export const colours = {
    orange: '#FF7057',
    orangeDark: '#CC5A46',
    orangeLight: '#fff0ee',
    orangeBg: '#FFE6E2',
    blue: '#001E3F',
    blueDark: '#01142B',
    allowaBlue: '#0E47C1',
    allowaBlueDark: '#0B399A',
    lightBlue: '#F1F4FC',
    lightBlueDark: '#ECF0FA',
    green: '#51D1AD',
    allowaGreen: '#26C598',
    allowaGreenDark: '#1C9674',
    offwhite: '#FAFAFA',
    black: '#0A0A0A',
    grey: '#7C8C9F',
}

// Breakpoints
export const size = {
    tablet: '767px',
    mobileTablet: '1023px',
    desktop: '1024px',
}

export const device = {
    smartphone: `(max-width: ${size.tablet})`,
    tablet: `(min-width: ${size.tablet})`,
    mobileTablet: `(max-width: ${size.mobileTablet})`,
    desktop: `(min-width: ${size.desktop})`,
}
